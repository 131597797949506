<!-- wxindex.vue -->
<template>
  <div class="wxindex-container">
    <div class="button-group">
      <button v-if="hasPermission(WX_MACHINETOOL_STATUS)" class="btn">机床管理系统</button>
      <button v-if="hasPermission(CRM_OPERATION)" class="btn" @click="goToCRM">作战地图</button>
    </div>

    <!-- 新增的注销按钮 -->
    <button class="logout-btn" @click="handleLogout">注销</button>
  </div>
</template>

<script>
import { post } from '@/apis/restUtils';

export default {
  data() {
    return {
      WX_SERVICE: '100021',  //微信互动
      CRM_OPERATION: '100023',  //CRM操作
      WX_MACHINETOOL_STATUS: '100024',  //机床状态查询
      permissions: [],
    };
  },
  mounted() {
    this.fetchPermissions();
  },
  methods: {
    fetchPermissions() {
      post('/wx/getPermissions', {}, (response) => {
        if (response && response.data && response.data.status === 'success') {
          this.permissions = response.data.entity;
        } else {
          console.error('获取权限失败');
        }
      });
    },
    hasPermission(code) {
      return this.permissions.includes(code);
    },
    goToCRM() {
      this.$router.push('/wxcrmindex');
    },
    // 新增的注销方法
    handleLogout() {
      this.$confirm('确定要注销当前账号吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.performLogout();
      }).catch(() => {
        // 用户取消操作
      });
    },
    // 预留的注销接口
    performLogout() {
      post('/wx/signOut', {}, (response) => {
        if (response && response.data && response.data.status === 'success') {
          this.$message.success('注销成功');
          // 这里可以添加注销成功后的跳转逻辑
          // 例如跳转到登录页面
          this.$router.push('/wxlogin');
        } else {
          this.$message.error('注销失败');
        }
      });
    }
  }
};
</script>

<style scoped>
.wxindex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(to bottom, #f0f2f5, #ffffff);
  padding: 20px 0; /* 添加上下内边距 */
  position: relative; /* 为注销按钮定位做准备 */
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 100%;
  height: 50px;
  font-size: 1.2rem;
  background: linear-gradient(135deg, #007aff, #005bb5);
  color: white;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* 新增的注销按钮样式 */
.logout-btn {
  position: fixed;
  bottom: 30px;
  width: 90%;
  max-width: 500px;
  height: 45px;
  font-size: 1rem;
  background: linear-gradient(135deg, #ff4d4f, #d9363e);
  color: white;
  border: none;
  border-radius: 25px; /* 更圆的边角 */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.logout-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.logout-btn:active {
  transform: translateY(0);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}
</style>